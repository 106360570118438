/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './UserHeader.scss';
import './dropdown.scss';
import { SideMenu } from '.';
import { translate } from '../../utils/text-utils';

import LogoImage from '../../assets/logo/images/logo-black.svg';
import {
  ArabicLocale,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  State,
  cartOpen,
  quoteOpen,
  setNotificationPopupVisibilityAction,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import BrandSelection from './BrandSlelection';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { useAuth, useCart, useProduct } from '../../core/hooks';
import { useQuotes } from '../../core/hooks/useQuotes';
import i18n from '../../i18n';
import {
  ISearchParams,
  Model,
  ProductFilter,
  UserPermissionTypes,
  UserType,
} from '../../core/api/models';
import SideMenuMob from './SideMenuMob';
import { NotificationPopup } from './notification-popup';
import { useNotifications } from '../../core/hooks/useNotifications';
import TabSearch from '../search-tab/search-tab';
import { decodeHtmlEntities } from '../../utils/common';

interface CustomProps {
  menuVisible?: boolean;
  mobHeader?: boolean;
  showBrand?: boolean;
}

const UserHeaderCanvas: React.SFC<CustomProps> = (props: CustomProps) => {
  const {
    getCarsV2,
    searchCarsvin,
    setSearchParam,
    totalRecord,
    loaded,
    setProductFilter,
    selectedFilter,
    model,
    setModel,
    brand,
    setBrand,
  } = useProduct();
  const { notificationState } = useNotifications();
  const { hasRole } = useAuth();
  const history = useHistory();
  const showBrand = props.showBrand ?? false;

  const cartOpenState = useSelector((state: State) => state.cart.cartOpen);
  const quoteOpenState = useSelector((state: State) => state.cart.quoteOpen);

  const [stateExpand, setStateExpand] = React.useState<boolean>(false);
  const [menuAccountOpen, toggleMenuAccountOpen] =
    React.useState<boolean>(false);
  const [brandSelectionOpen, setBrandSelectionOpen] =
    React.useState<boolean>(false);

  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [brand, setBrand] = useState('');
  // const [model, setModel] = useState('');
  const [modelDetails, setModelDetails] = useState<Model>();
  const [vinNumber, setVinNumber] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchFilter, toggleSearchFilter] = useState(false);
  const { cart } = useCart();
  const { quotations } = useQuotes();

  const authState = useSelector((state: State) => state.authUser);
  const domain = authState?.userToken ? '/' : '/dashboard';
  const { brandState, getBrandAndBranches } = useBrandsAndBranches();
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    if (authState?.userToken) {
      getBrandAndBranches(authState?.userToken);
    }
  }, [authState.userToken]);
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const getCars = (
    searchbrand?: string | undefined,
    searchmodel?: string | undefined,
    searchVin?: string | undefined,
    filters?: ProductFilter[],
    pageNo?: number
  ) => {
    if (searchbrand && searchmodel) {
      getCarsV2(searchmodel, filters, pageNo);
    } else if (searchVin) {
      searchCarsvin(searchVin, filters, pageNo);
    }
  };
  const { loadNotifications } = useNotifications();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      loadNotifications();
    }
  }, []);

  const onSearch = (selectedBrand: string, selectedmodel: string) => {
    let url = `/search-result?b=${selectedBrand}`;
    if (selectedmodel) {
      url = url + `&m=${selectedmodel}`;
    }

    // history.replace(url);
    setBrand(selectedBrand);
    setModel(selectedmodel);
    setVinNumber('');
    if (selectedBrand && selectedmodel) {
      setSearched(true);
      // window.history.pushState(
      //   {
      //     id: 'homepage',
      //   },
      //   'pageTitle',
      //   url
      // );
      history.replace(url);
      setIsLoading(true);
      getCars(selectedBrand, selectedmodel, '', selectedFilter, 1);
      setFilterOpen(true);
    }
  };

  const onSearchVin = (vin: string) => {
    setSearched(true);
    setIsLoading(true);
    getCars('', '', vin, selectedFilter, 1);
    setBrand('');
    setModel('');
    setVinNumber(vin);
    const url = `/search-result?vin=${vin}`;
    window.history.pushState(
      {
        id: 'homepage',
      },
      'pageTitle',
      url
    );
    // history.replace(url);
  };
  const { pathname } = useLocation();
  return (
    <>
      <header className="header border-bottom d-lg-block d-none">
        <div className="header__bottom py-sm-3 py-2">
          <div className="container py-1">
            <div className="row gutter-10 align-items-center">
              <div className="col-auto">
                <div className="position-relative menu-exp">
                  <div
                    className={`text-gray-700 cursor-pointer ${
                      stateExpand === true ? 'active' : ''
                    }`}
                    onClick={() => setStateExpand(!stateExpand)}
                  >
                    <i className="icon-menu d-block"></i>
                    <span className="d-block text-center font-weight-medium">
                      {translate('Header.MENU')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-auto mr-sm-3">
                <Link to={`${domain}`} className="Logo mb-1">
                  <svg
                    width="87"
                    height="27"
                    viewBox="0 0 116 38"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1212_5816)">
                      <path d="M75.5151 10.9181C75.0078 10.8175 74.555 10.742 74.1525 10.6917C73.75 10.6414 73.3265 10.6162 72.8905 10.6162C70.9367 10.6162 69.335 11.1319 68.0898 12.1591C67.1674 12.9222 66.4966 13.853 66.0731 14.9557L65.767 11.1193H60.8154V35.8857H66.0731V23.1483C66.0731 20.7542 66.723 18.9262 68.0185 17.6642C69.3141 16.4022 71.0918 15.7691 73.3517 15.7691H75.5235V10.9181H75.5151Z"></path>
                      <path d="M82.4918 0C81.5485 0 80.756 0.318647 80.1146 0.960133C79.4731 1.60162 79.1544 2.39404 79.1544 3.33741C79.1544 4.28077 79.4731 5.03126 80.1146 5.68952C80.756 6.34778 81.5485 6.67481 82.4918 6.67481C83.4352 6.67481 84.1857 6.34778 84.8439 5.68952C85.5022 5.03126 85.8292 4.24723 85.8292 3.33741C85.8292 2.42759 85.5022 1.60162 84.8439 0.960133C84.1857 0.318647 83.4016 0 82.4918 0Z"></path>
                      <path d="M79.8629 28.3638C79.8629 29.6761 79.6365 30.6656 79.1795 31.3197C78.7225 31.9779 77.9427 32.305 76.8274 32.305C76.4249 32.305 76.0601 32.2798 75.7415 32.2295C75.4228 32.1792 75.0413 32.087 74.6053 31.9528L73.6954 36.6528C74.4375 36.8541 75.0706 36.9882 75.5905 37.0553C76.1146 37.1224 76.6765 37.156 77.2844 37.156C79.7791 37.156 81.7077 36.4055 83.0704 34.9045C84.433 33.4035 85.1164 31.391 85.1164 28.8628V11.0688H79.8587V28.3638H79.8629Z"></path>
                      <path d="M94.4201 0C93.4767 0 92.6759 0.318647 92.0176 0.960133C91.3594 1.60162 91.0323 2.39404 91.0323 3.33741C91.0323 4.28077 91.3594 5.03126 92.0176 5.68952C92.6759 6.34778 93.4767 6.67481 94.4201 6.67481C95.3634 6.67481 96.1055 6.34778 96.747 5.68952C97.3885 5.03126 97.7072 4.24723 97.7072 3.33741C97.7072 2.42759 97.3885 1.60162 96.747 0.960133C96.1055 0.318647 95.3299 0 94.4201 0Z"></path>
                      <path d="M97.0489 11.0688H91.7913V35.8856H97.0489V11.0688Z"></path>
                      <path d="M115.279 31.1477C109.853 32.2798 109.774 30.0325 109.77 28.238C109.77 26.9466 109.77 24.1165 109.77 24.1165V15.4669H114.57V11.0688H109.77V3.3374H104.512V11.073H100.336V15.4711H104.512V24.1207C104.512 24.1207 104.495 28.435 104.512 30.0408C104.604 39.0133 116 35.8603 116 35.8603L115.279 31.1561V31.1477Z"></path>
                      <path d="M49.386 12.0793C47.4322 10.9683 45.1891 10.4106 42.6651 10.4106C40.1411 10.4106 37.9399 10.9683 35.9693 12.0793C33.9987 13.1904 32.4474 14.725 31.3196 16.6788C30.1917 18.6326 29.6257 20.8924 29.6257 23.45C29.6257 26.0076 30.1876 28.2674 31.3196 30.2212C32.4474 32.1751 33.9987 33.7096 35.9693 34.8207C37.9399 35.9317 40.1704 36.4894 42.6651 36.4894C45.1598 36.4894 47.4322 35.9317 49.386 34.8207C51.3398 33.7096 52.8828 32.1751 54.0106 30.2212C55.1384 28.2674 55.7045 26.0076 55.7045 23.45C55.7045 20.8924 55.1384 18.6326 54.0106 16.6788C52.8828 14.725 51.3398 13.1904 49.386 12.0793Z"></path>
                      <path d="M19.7645 12.0793C17.8107 10.9683 15.5676 10.4106 13.0436 10.4106C10.5195 10.4106 8.31836 10.9683 6.34778 12.0793C4.3772 13.1904 2.82589 14.725 1.69805 16.6788C0.561825 18.6368 0 20.8924 0 23.4542C0 26.0159 0.561825 28.2716 1.69386 30.2254C2.8217 32.1792 4.37301 33.7138 6.34359 34.8249C8.31416 35.9359 10.5447 36.4936 13.0394 36.4936C15.534 36.4936 17.8065 35.9359 19.7603 34.8249C21.7141 33.7138 23.257 32.1792 24.3849 30.2254C25.5127 28.2716 26.0787 26.0118 26.0787 23.4542C26.0787 20.8966 25.5127 18.6368 24.3849 16.683C23.257 14.7291 21.7141 13.1946 19.7603 12.0835L19.7645 12.0793ZM13.0394 31.7348C8.07937 31.7348 4.93483 28.0285 4.93483 23.4542C4.93483 18.8799 8.07937 15.1736 13.0394 15.1736C17.9993 15.1736 21.1439 18.8799 21.1439 23.4542C21.1439 28.0285 17.9993 31.7348 13.0394 31.7348Z"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_1212_5816">
                        <rect width="116" height="37.1601" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
              {showBrand && (
                <div className="col-auto mt-sm-0 mt-3">
                  <div
                    className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer"
                    onClick={() => setBrandSelectionOpen(true)}
                  >
                    <span className="font-weight-bold text-gray-900 border-bottom border-dark border-width-2 mr-2">
                      {decodeHtmlEntities(
                        brandState?.selectedBrand?.brandName ?? ''
                      )}
                    </span>
                    {decodeHtmlEntities(
                      brandState?.selectedBranch?.warehouseName ?? ''
                    )}
                    <i className="icon-arrow-down text-primary ml-2 pl-1 font-sm"></i>
                  </div>
                </div>
              )}
              <div className="col-12 col-md d-md-flex justify-content-end d-none d-md-block">
                <ul className="header__nav d-flex align-items-center list-unstyled m-0 flex-wrap font-weight-bold line-height">
                  <li
                    onClick={() => {
                      history.push(`${RouteKeys.Offers}`);
                    }}
                    className="header__nav-inner position-relative ml-xl-5 ml-4 d-flex align-items-center cursor-pointer"
                  >
                    <i className="icon-offers"></i>
                    <p className="m-0 font-md pl-2 text-uppercase text-gray-700 d-lg-inline-block d-none">
                      {translate('Header.OFFERS')}
                    </p>
                  </li>
                  {((authState.userType === UserType.Salesperson &&
                    authState.checkedInCustomer &&
                    hasRole(UserPermissionTypes.bulk_order)) ||
                    ((authState.userType === UserType.Customer ||
                      authState.userType === UserType.User) &&
                      hasRole(UserPermissionTypes.bulk_order))) && (
                    <li className="header__nav-inner position-relative ml-xl-5 ml-4">
                      <Link
                        to={RouteKeys.BulkOrder}
                        className="d-flex align-items-center text-gray-700"
                      >
                        <i className="icon-bulk h5 m-0"></i>
                        <p className="m-0 font-md pl-2 text-uppercase d-lg-inline-block d-none align-text">
                          {translate('Header.BULK_ORDER')}
                        </p>
                      </Link>
                    </li>
                  )}
                  <li className="header__nav-inner position-relative ml-xl-5 ml-4 d-lg-inline-flex align-items-center d-none">
                    <span
                      className="m-0 font-md font-arabic line-height-normal cursor-pointer align-text-top"
                      onClick={() => {
                        if (settings.locale.languageID === 1) {
                          onLanguageChange(new ArabicLocale());
                        } else {
                          onLanguageChange(new EnglishLocale());
                        }
                      }}
                    >
                      {settings?.locale?.languageID === 1 ? 'عربي' : 'English'}
                    </span>
                  </li>
                  <li
                    className={`header__nav-notification position-relative ml-xl-5 ml-4 d-flex cursor-pointer ${
                      unreadNotificationCount > 0 ? 'mr-3' : ''
                    }`}
                    onClick={() => {
                      dispatch(setNotificationPopupVisibilityAction(true));
                    }}
                  >
                    <i className="icon-due-notification"></i>
                    {unreadNotificationCount > 0 && (
                      <span className="noti position-absolute">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {props.mobHeader && (
        <header className="d-lg-none header-mobile py-5 position-relative mb-4">
          <div className="container-fluid">
            <div className="row gutter-12">
              <div className="col">
                <h1 className="text-white mb-0 font-weight-bold">
                  {translate('Header.Discover')}
                </h1>
                <p className="text-white font-sm mb-0">
                  {translate('Header.Everthing_you_needed_for_your_vehicle')}
                </p>
              </div>
              <div className="col-auto">
                <div className="pt-3 mt-1 d-flex align-items-center">
                  <i className="icon-social-media h5 mb-0 text-white mr-2 position-relative">
                    {unreadNotificationCount > 0 && (
                      <span className="noti-count font-xs text-white position-absolute rounded-pill text-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </i>
                  <div
                    className={`language-switch rounded-pill d-inline-flex align-items-center text-center position-relative ${
                      settings.locale.languageID === 1 ? 'en' : 'ar'
                    }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    <span className="en">EN</span>
                    <span className="ar">AR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-search position-absolute w-100 bg-white rounded d-flex no-gutters align-items-center shadow">
            <div className="col px-2 minw-0">
              <div
                className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer w-100"
                onClick={() => setBrandSelectionOpen(true)}
              >
                <span className="font-weight-bold text-gray-900 text-uppercase text-nowrap">
                  {decodeHtmlEntities(
                    brandState?.selectedBrand?.brandName ?? ''
                  )}{' '}
                  -&nbsp;
                </span>
                <span className="text-truncate">
                  {decodeHtmlEntities(
                    brandState?.selectedBranch?.warehouseName ?? ''
                  )}
                </span>
                <i className="icon-arrow-down text-gray-700 ml-2 pl-1 font-xxxs"></i>
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  // toggleSearchFilter(true)
                  const params: ISearchParams = {
                    model: undefined,
                    vin: '',
                    partNumber: '',
                  };
                  setSearchParam(params);
                  history.push(`${RouteKeys.SearchResult}`);
                }}
              >
                {translate('Header.Search')}
              </button>
            </div>
          </div>
        </header>
      )}
      <SideMenu
        isExpand={stateExpand}
        expandToggle={() => setStateExpand(!stateExpand)}
        menuVisible={props.menuVisible}
      />
      {menuAccountOpen && (
        <SideMenuMob
          isExpand={menuAccountOpen}
          expandToggle={() => setStateExpand(!menuAccountOpen)}
          menuVisible={props.menuVisible}
        />
      )}
      <BrandSelection
        isOpen={brandSelectionOpen}
        togglePopup={() => setBrandSelectionOpen(false)}
      />
      <footer className="d-lg-none position-fixed bottom-0 left-0 w-100 zIndex-9 bg-white text-center footer-mobile">
        <div className="container-fluid">
          <div className="row gutter-12">
            <div className="col">
              <Link
                to="/"
                onClick={() => {
                  if (menuAccountOpen === true) {
                    toggleMenuAccountOpen(false);
                  }
                }}
                className={`pt-2 pb-3 footer-menu d-inline-block  ${
                  pathname === '/' && !menuAccountOpen ? 'active' : ''
                }`}
              >
                <i className="icon-footer-home h4 mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Home')}</p>
              </Link>
            </div>
            <div className="col">
              <div onClick={() => toggleMenuAccountOpen(true)}>
                <span
                  className={`pt-2 pb-3 footer-menu d-inline-block cursor-pointer ${
                    menuAccountOpen ? 'active' : ''
                  }`}
                >
                  <i className="icon-footer-account h4 mb-1"></i>
                  <p className="m-0 font-xs">{translate('Header.Account')}</p>
                </span>
              </div>
            </div>
            <div className="col">
              <div
                className={`pt-2 pb-3 footer-menu ${
                  pathname === 'search' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleMenuAccountOpen(false);
                  toggleSearchFilter(true);
                }}
              >
                <i className="icon-footer-search h4 mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Search')}</p>
              </div>
            </div>
            <div className="col">
              <div
                className="pt-2 pb-3 footer-menu"
                onClick={() => dispatch(quoteOpen(!quoteOpenState))}
              >
                <i className="icon-footer-quotes h4 mb-1 position-relative">
                  {!!quotations?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {quotations?.length}
                    </span>
                  )}
                </i>
                <p className="m-0 font-xs">{translate('Header.Quotes')}</p>
              </div>
            </div>
            <div className="col">
              <div
                className="pt-2 pb-3 footer-menu"
                onClick={() => dispatch(cartOpen(!cartOpenState))}
              >
                <i className="icon-footer-cart h4 mb-1 position-relative">
                  {!!cart?.items?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {cart?.items?.length}
                    </span>
                  )}
                </i>

                <p className="m-0 font-xs">{translate('Header.Cart')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {searchFilter && (
        <div className="d-lg-none position-fixed top-0 left-0 w-100 h-100 bg-body zIndex-9">
          <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white">
            <i
              className="icon-arrow-left mr-3 font-lg"
              onClick={() => toggleSearchFilter(false)}
            ></i>
            {brandState?.selectedBrand?.brandName ?? ''}{' '}
            {brandState?.selectedBranch?.warehouseName ?? ''}
          </div>
          <TabSearch
            setIsSearched={(data) => {
              history.push(`${RouteKeys.SearchResult}`);
            }}
          />
        </div>
      )}
      {notificationState.isPopupOpened && (
        <NotificationPopup
          title="Notifications"
          onClose={() => {
            dispatch(setNotificationPopupVisibilityAction(false));
          }}
        />
      )}
    </>
  );
};

export default UserHeaderCanvas;
